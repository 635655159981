// 产品详情
<template>
  <div class="product-details-wrapper">
    <Bigimg
      title="热门产品"
      :imgUrl="require('@/assets/images/hot.jpg')"
    ></Bigimg>
    <div class="product-details wrap-v1">
      <div class="product-details-list">
        <imgLeftorright
          v-for="(item, index) in hotProducts"
          :key="item.id"
          @click="goDetail(item)"
          :title="item.title"
          :desc="item.desc"
          :imgUrl="item.bgUrl"
          :direction="index % 2 === 0 ? 'right' : 'left'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import imgLeftorright from "@/components/img-leftorright";
import Bigimg from "@/components/bigimg.vue";
import productList from "./product";
export default {
  components: { imgLeftorright, Bigimg },
  computed: {
    hotProducts() {
      return productList;
    }
  },
  methods: {
    goDetail(item) {
      this.$router.push(`/product/details/${item.id}`);
    }
  }
};
</script>
<style scoped lang="scss">
.product-details {
  padding: 40px 20px 115px;
  .product-details-list {
    .product-details-item {
      display: flex;
      align-items: center;
      margin-top: 60px;
      img {
        flex: 1;
        display: block;
        width: 700px;
        /* width: 50%; */
        /* min-width: 500px; */
      }
      .product-details-detail {
        flex: 1;
        text-align: left;
        .product-details-title {
          font-size: 45px;
          font-weight: 600;
          color: hsla(0, 0%, 20%, 1);
          line-height: 63px;
        }
        .product-details-desc {
          margin-top: 45px;
          text-align: left;
          font-size: 20px;
          color: hsla(0, 0%, 20%, 1);
          line-height: 40px;
        }
      }
    }
  }
}
</style>
